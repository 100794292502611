import { Controller } from "stimulus";
import Hls from "hls.js";
import Plyr from "plyr";

export default class extends Controller {
  static targets = [
    "controlsTemplate",
    "videoContainer",
    "popoutPlaceholder"
  ]

  static values = {
    timestamp: Number,
    markers: Array,
    episodeId: Number,
    floating: Boolean,
    mediaMetadata: Object
  }

  async connect() {
    this.video = this.element.querySelector('video');

    this.initializePlayer();
    this.boundbeforeVisitPopOut = this.beforeVisitPopOut.bind(this);
    this.boundReadyToPlay = this.readyToPlay.bind(this);

    if (this.hasFloatingValue && !this.floatingValue) {
      window.addEventListener("turbo:before-visit", this.boundbeforeVisitPopOut);
    }

    if (!this.video.src) {
      this.initializeVideo();
    }
  }

  async initializeVideo() {
    this.hls = new Hls();

    this.video.addEventListener('canplay', this.boundReadyToPlay);

    // Try to fetch the hls playlist url, if we can't find it then fall back to the original
    this.hlsUrl = this.element.getAttribute('data-playlist-url');
    this.originalUrl = this.element.getAttribute('data-original-url');

    const response = await fetch(this.hlsUrl, { method: 'HEAD' });

    if (response.ok) {
      this.loadHlsUrl();
    } else {
      this.loadOriginalUrl();
    }
  }

  loadHlsUrl() {
    if (Hls.isSupported()) {
      var video = this.video;
      this.hls.loadSource(this.hlsUrl);
      this.hls.attachMedia(video);
    } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      const url = this.element.getAttribute('data-playlist-url');
      this.video.src = this.hlsUrl;
    } else {
      this.loadOriginalUrl();
    }
  }

  loadOriginalUrl() {
    this.video.src = this.originalUrl;
  }

  initializePlayer() {
    this.player = new Plyr(this.video, {
      autoplay: true,
      controls: this.controlsTemplateTarget.innerHTML,
      mediaMetadata: this.mediaMetadataValue,
      seekTime: 15,
    });
  }

  onTimeUpdate() {
    const event = new CustomEvent("supercast-timeupdate", {
      detail: {
        itemId: this.element.getAttribute('data-item-id'),
        itemType: this.element.getAttribute('data-item-type'),
        currentTime: this.player.currentTime,
        duration: this.player.duration,
        isPlaying: !this.player.paused,
        mediaType: "video"
      }
    });

    this.updateMediaSessionPlaybackState();

    window.dispatchEvent(event);
  }

  loadFull() {
    window.Turbo.visit(`/subscriber_v2/${this.element.getAttribute('data-item-type').toLowerCase()}s/${this.element.getAttribute('data-item-id')}?timestamp=${this.player.currentTime}`);
    this.close();
  }

  popOut() {
    window.Turbo.visit(
      `/subscriber_v2/${this.element.getAttribute('data-item-type').toLowerCase()}s/${this.element.getAttribute('data-item-id')}/play?timestamp=${this.player.currentTime}`,
      { frame: "floating-player" }
    );
    this.player.pause();
    this.popoutPlaceholderTarget.classList.remove("hidden");
    this.videoContainerTarget.classList.add("hidden");
  }

  beforeVisitPopOut(event) {
    if (this.hasFloatingValue && this.floatingValue) {
      return;
    }

    if (!event.detail.url.includes("/videos") && !event.detail.url.includes("/episodes")) {
      this.popOut();
      window.removeEventListener("turbo:before-visit", this.boundbeforeVisitPopOut);
    }
  }

  close() {
    this.element.remove();
  }

  async readyToPlay() {
    this.video.removeEventListener('canplay', this.boundReadyToPlay);
    if (this.hasTimestampValue && this.timestampValue > 0) {
      this.video.currentTime = this.timestampValue;
    } else {
      const listenData = await window.playbackDb.getPlaybackData(this.element.getAttribute('data-item-type'), this.element.getAttribute('data-item-id'));
      if (listenData) {
        this.video.currentTime = listenData.currentTime;
      }
    }

    this.player.on("timeupdate", this.onTimeUpdate.bind(this));
    this.video.play();
  }

  updateMediaSessionPlaybackState() {
    if (!("mediaSession" in navigator)) {
      return;
    }

    navigator.mediaSession.setPositionState({
      duration: this.player.duration,
      playbackRate: this.player.speed,
      position: this.player.currentTime
    });

    navigator.mediaSession.playbackState = this.player.paused ? "paused" : "playing";

  }
}