import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["planSelect", "channelSelect", "settings", "targetText"];

  async connect() {
    await this.fetchPlans();
    this.updateFromForm();
    if (this.hasChannelSelectTarget) {
      this.initChannelSelect();
    }

    this.element.addEventListener("change", this.updateFromForm.bind(this));
  }

  show() {
    this.settingsTarget.classList.remove("hidden");
  }

  hide() {
    this.settingsTarget.classList.add("hidden");
  }

  updateFromForm() {
    const selectedItem = this.element.querySelector("input[type='radio']:checked");
    const targetingType = selectedItem.value;

    this.setTargetText(targetingType);
    this.setTargetingVisibility(targetingType);
  }

  setTargetText(targetingType) {
    let text = "";

    if (targetingType === "all") {
      text = "All Subscribers";
    } else if (targetingType === "plan") {
      const value = this.plansTom.getValue().split(",").filter(v => v.length > 0);
      if (value.length === 0) {
        text = "Select a Plan";
      } else if (value.length === 1) {
        text = this.plansTom.getItem(value[0]).textContent;
      } else {
        text = `${value.length} Plans`;
      }
    } else if (targetingType === "channel") {
      const value = this.channelSelectTarget.value;
      text = this.channelSelectTarget.options[this.channelSelectTarget.selectedIndex].text;
    }

    this.targetTextTarget.textContent = text;
  }

  setTargetingVisibility(targetingType) {
    if (targetingType === "all") {
      this.hideTom(this.plansTom);
      this.hideTom(this.channelTom);
    } else if (targetingType === "plan") {
      this.showTom(this.plansTom);
      this.hideTom(this.channelTom);
    } else if (targetingType === "channel") {
      this.hideTom(this.plansTom);
      this.showTom(this.channelTom);
    }
  }

  hideTom(tom) {
    if (!tom) return;

    tom.clear();
    tom.wrapper.classList.add("hidden");
  }

  showTom(tom) {
    if (!tom) return;

    tom.wrapper.classList.remove("hidden");
  }

  async fetchPlans() {
    const response = await fetch("/dashboard/shared/plans.json?all=true");
    const { public_plans, unlisted_plans, archived_plans } = await response.json();
    const config = {
      options: [
        ...public_plans.map(plan => ({ value: plan.id, label: plan.name, subscribers: plan.subscription_count, optgroup: "public" })),
        ...unlisted_plans.map(plan => ({ value: plan.id, label: plan.name, subscribers: plan.subscription_count, optgroup: "unlisted" })),
        ...archived_plans.map(plan => ({ value: plan.id, label: plan.name, subscribers: plan.subscription_count, optgroup: "archived" }))
      ],
      optgroups: [
        { value: "public", label: "Public Plans" },
        { value: "unlisted", label: "Unlisted Plans" },
        { value: "archived", label: "Archived Plans" },
      ],
      optgroupField: "optgroup",
      labelField: "label",
      valueField: "value",
      searchField: "label",
      render: {
        optgroup_header: function (data, escape) {
          return '<div class="p-2 text-gray-400">' + escape(data.label) + '</div>';
        },
        option: function (data, escape) {
          return "<div>" + escape(data.label) + " <span class='text-gray-400'>(" + data.subscribers + " subscribers)</span></div>";
        }
      }
    }

    this.plansTom = new TomSelect(this.planSelectTarget, config);
  }

  initChannelSelect() {
    this.channelTom = new TomSelect(this.channelSelectTarget, {});
  }
}