/**
 * Toggles amounts for donations / extra funds for subscriptions.
 */

import { Controller } from "stimulus"
export default class extends Controller {

  static targets = ["custom", "price"];

  connect() {
    this.basePrice = parseInt(this.element.dataset.basePrice);
    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.addEventListener('change', (e) => {
        this.useAnchor(e);
        this.updatePreview(e);
      });
    });
  }

  useCustom() {
    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.checked = false;
    });
    if (this.customTarget.value === "") {
      this.customTarget.value = this.customTarget.min;
    }
  }

  useAnchor() {
    this.customTarget.value = "";
  }

  currentExtraSupport() {
    const radio = this.element.querySelector("input[type='radio']:checked");
    const value = radio ? radio.value : (this.customTarget.value - this.basePrice) * 100;
    if (value > 0) {
      return value;
    }
  }

  updatePreview() {
    this.priceTargets.forEach((target) => {
        const url = new URL(target.src, window.location.href);
        url.searchParams.set('subscription[extra_support_cents]', this.currentExtraSupport());
        target.src = url.toString();
    });
  }
}
